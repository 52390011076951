import { darken } from "polished";
import styled from "styled-components";
import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

import SettingsModal from "../SettingsModal";

const SettingsIcon = styled(FontAwesomeIcon)`
  color: #212529;
  cursor: pointer;

  &:hover {
    color: ${darken("10%", "#212529")};
  }
`;

const Settings = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <SettingsIcon icon={faCog} onClick={() => setIsOpen(true)} />
      <SettingsModal isOpen={isOpen} close={() => setIsOpen(false)} />
    </>
  );
};

export default Settings;
