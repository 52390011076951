import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";

import Firebase from "../../lib/firebase";

const Section = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 24px;
`;

const Signin = () => (
  <Section>
    <Title>Monthly Tracker</Title>
    <Button onClick={() => Firebase.instance().doGoogleSignin()}>
      Sign in with Google
    </Button>
  </Section>
);

export default Signin;
