import * as firebase from "firebase/app";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import Calendar from "../Calendar";
import Display from "../Display";
import SettingsButton from "../Settings";
import Signin from "../Signin";

import appContext, {
  AppContext,
  initialSettings,
} from "../../contexts/appContext";

import calories from "../../lib/calories";
import Firebase from "../../lib/firebase";
import { Data, Firestore, Settings } from "../../lib/firebase/types";
import { phases } from "../../lib/phase";

import { FACTORS } from "./constants";
import { CyclePhase } from "./types";

const Header = styled.header`
  display: flex;
  font-size: 8vh;
  height: 10vh;
  line-height: 8vh;
  padding: 1vh 16px;
  position: relative;
`;

const Loading = styled.section`
  content: "Loading...";
  height: 100vh;
  padding: 16px;
  text-align: center;
`;

const DisplaySection = styled.section`
  height: 35vh;
  padding: 16px;
`;

const CalendarSection = styled.section`
  height: 50vh;
  padding: 16px;
`;

const Title = styled.div`
  flex: 1;
`;

const App = () => {
  const [data, setData] = useState<Record<string, Data>>({});
  const [selected, setSelected] = useState<moment.Moment>(
    moment().startOf("day")
  );
  const [settings, setSettings] = useState<Settings>(initialSettings);
  const [user, setUser] = useState<firebase.User | null | undefined>(undefined);
  useEffect(() => Firebase.instance().auth.onAuthStateChanged(setUser), []);
  useEffect(() => {
    const doc = Firebase.instance().getSettingsDoc(user);
    if (!doc) return;
    return doc.onSnapshot((doc) => {
      const data: Firestore<Settings> | undefined = doc.data() as
        | Firestore<Settings>
        | undefined;
      if (!data) {
        return;
      }
      const converted: Settings = {
        ...data,
        periodStart: new Date((data.periodStart?.seconds || 0) * 1000),
      };
      setSettings({ ...initialSettings, ...converted });
    });
  }, [user]);
  useEffect(() => {
    const doc = Firebase.instance().getSettingsDoc(user);
    if (!doc) return;
    return doc
      .collection("data")
      .orderBy("date")
      .startAt(moment().subtract(2, "months").startOf("day").toDate())
      .onSnapshot((snapshot) => {
        setData((d) =>
          snapshot.docChanges().reduce((d, change) => {
            const doc: Firestore<Data> = change.doc.data() as Firestore<Data>;
            if (["added", "modified"].includes(change.type)) {
              return {
                ...d,
                [moment(doc.date.seconds * 1000)
                  .unix()
                  .toString()]: {
                  ...doc,
                  date: new Date(doc.date.seconds * 1000),
                },
              };
            }
            if (change.type === "removed") {
              const {
                [moment(doc.date.seconds * 1000)
                  .unix()
                  .toString()]: toRemove,
                ...rest
              } = d;
              return rest;
            }
            return d;
          }, d)
        );
      });
  }, [user]);

  const bmr = useMemo(
    () =>
      calories.bmr(
        settings.sex,
        settings.weight,
        settings.height,
        settings.age
      ),
    [settings.sex, settings.weight, settings.height, settings.age]
  );
  const tee = useMemo(() => calories.tee(bmr, settings.activityLevel), [
    bmr,
    settings.activityLevel,
  ]);
  const baseTarget = useMemo(() => calories.target(tee, settings.target), [
    tee,
    settings.target,
  ]);
  const target: Record<CyclePhase, number> = useMemo(
    () =>
      phases.reduce(
        (acc, phase) => ({
          ...acc,
          [phase]: Math.floor(baseTarget * FACTORS[phase]),
        }),
        {} as Record<CyclePhase, number>
      ),
    [baseTarget]
  );

  if (user === undefined) {
    return <Loading />;
  }

  if (user === null) {
    return <Signin />;
  }

  const value: AppContext = {
    calories: { bmr, target, tee },
    data,
    selected,
    setSelected,
    settings,
    user,
  };

  return (
    <appContext.Provider value={value}>
      <Header>
        <Title>Tracker</Title>
        <SettingsButton />
      </Header>
      <DisplaySection>
        <Display />
      </DisplaySection>
      <CalendarSection>
        <Calendar />
      </CalendarSection>
    </appContext.Provider>
  );
};

export default App;
