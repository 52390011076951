import * as firebase from "firebase/app";
import "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

class Firebase {
  private static _instance: Firebase | undefined;
  private _auth: firebase.auth.Auth;
  private _firestore: firebase.firestore.Firestore;

  private constructor() {
    firebase.initializeApp(config);

    this._auth = firebase.auth();
    this._firestore = firebase.firestore();
  }

  public static instance(): Firebase {
    if (!Firebase._instance) {
      Firebase._instance = new Firebase();
    }
    return Firebase._instance;
  }

  public doGoogleSignin() {
    this.auth.signInWithRedirect(new firebase.auth.GoogleAuthProvider());
  }

  public getSettingsDoc(
    user: firebase.User | null | undefined
  ): firebase.firestore.DocumentReference | null {
    if (!user || !user.email) return null;
    return this.firestore.collection("users").doc(user.email);
  }

  public get auth(): firebase.auth.Auth {
    return this._auth;
  }

  public get firestore(): firebase.firestore.Firestore {
    return this._firestore;
  }
}

export default Firebase;
