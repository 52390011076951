import * as Moment from "moment";
import { extendMoment } from "moment-range";
import { darken } from "polished";
import React, { useContext, useMemo, useState } from "react";
import {
  Calendar as Cal,
  CalendarProps,
  momentLocalizer,
} from "react-big-calendar";

import { COLORS } from "../App/constants";
import { CycleEvent } from "../App/types";

import appContext from "../../contexts/appContext";

import phase from "../../lib/phase";

const moment = extendMoment(Moment);
const localizer = momentLocalizer(moment);

const Calendar = () => {
  const { calories, data, selected, setSelected, settings } = useContext(
    appContext
  );

  const today = moment().startOf("day");

  const [month, setMonth] = useState(today.clone().startOf("month"));

  const range = useMemo(
    () =>
      moment.range(
        month.clone().startOf("week"),
        month.clone().endOf("month").endOf("week")
      ),
    [month]
  );

  const eventPropGetter = (
    event: CycleEvent
  ): React.HTMLAttributes<HTMLDivElement> => ({
    style: {
      backgroundColor: COLORS[event.phase],
      border: `3px solid ${
        selected.isSame(event.start)
          ? darken(0.1, COLORS[event.phase])
          : COLORS[event.phase]
      }`,
      color: event.data
        ? event.data.calories > event.calories
          ? "red"
          : "green"
        : "black",
      fontStyle: event.data ? "normal" : "italic",
      fontWeight: event.data ? 600 : 400,
      outline: "none",
      textAlign: "center",
    },
  });

  const onRangeChange: CalendarProps["onRangeChange"] = (range) => {
    let start, end;
    if (range instanceof Array) {
      start = moment(range[0]);
      end = moment(range[range.length - 1]);
    } else {
      start = moment(range.start);
      end = moment(range.end);
    }
    setMonth(moment.range(start, end).center().startOf("month"));
  };

  const events: CycleEvent[] = Array.from(range.by("day")).map((day) => {
    const dayData = data[day.unix().toString()];
    const dayPhase = phase(day, settings);
    const target = calories.target[dayPhase];
    const sign = dayData && dayData.calories > target ? "+" : "-";
    const diff = dayData
      ? ` (${sign}${Math.abs(target - dayData.calories).toLocaleString()})`
      : "";

    return {
      allDay: true,
      calories: target,
      data: dayData,
      end: day.toDate(),
      phase: dayPhase,
      start: day.toDate(),
      title: `${target.toLocaleString()}${diff}`,
    };
  });

  return (
    <Cal<CycleEvent>
      className="d-none d-md-block"
      defaultDate={today.toDate()}
      defaultView="month"
      drilldownView={null}
      events={events}
      eventPropGetter={eventPropGetter}
      localizer={localizer}
      onSelectEvent={(event) => setSelected(moment(event.start))}
      onRangeChange={onRangeChange}
      views={["month"]}
    />
  );
};

export default Calendar;
