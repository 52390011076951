import moment from "moment";
import { CyclePhase } from "../../components/App/types";
import { Settings } from "../firebase/types";

export const phases: CyclePhase[] = [
  "menstrual",
  "follicular",
  "ovulation",
  "luteal",
  "none",
];

interface CacheDeps {
  periodStart?: number;
  sex?: Settings["sex"];
}
interface Cache {
  phase: Record<number, CyclePhase>;
  phaseBegin: Record<number, Readonly<moment.Moment>>;
  cycleBegin: Record<number, Readonly<moment.Moment>>;
  cycleEnd: Record<number, Readonly<moment.Moment>>;
}

const CACHE_DEPS: CacheDeps = {};
const initialCache = {
  cycleBegin: {},
  cycleEnd: {},
  phase: {},
  phaseBegin: {},
};
const CACHE: Cache = initialCache;

const checkDeps = (settings: Settings) => {
  if (
    CACHE_DEPS.periodStart !== settings.periodStart?.getTime() ||
    CACHE_DEPS.sex !== settings.sex
  ) {
    CACHE_DEPS.periodStart = settings.periodStart?.getTime();
    CACHE_DEPS.sex = settings.sex;
    CACHE.cycleBegin = {};
    CACHE.cycleEnd = {};
    CACHE.phase = {};
    CACHE.phaseBegin = {};
  }
};

const phase = (date: moment.Moment, settings: Settings): CyclePhase => {
  if (settings.sex === "male") {
    return "none";
  }

  checkDeps(settings);
  if (!CACHE.phase[date.unix()]) {
    CACHE.phase[date.unix()] =
      phases[
        Math.floor(
          (((date.diff(settings.periodStart, "days") % 28) + 28) % 28) / 7
        )
      ];
  }
  return CACHE.phase[date.unix()];
};

export const phaseBegin = (
  date: moment.Moment,
  settings: Settings
): Readonly<moment.Moment> => {
  if (settings.sex === "male") {
    return moment(date).startOf("week");
  }

  checkDeps(settings);
  if (!CACHE.phaseBegin[date.unix()]) {
    CACHE.phaseBegin[date.unix()] = Object.freeze(
      moment(date).subtract(
        ((date.diff(settings.periodStart, "days") % 7) + 7) % 7,
        "days"
      )
    );
  }
  return CACHE.phaseBegin[date.unix()];
};

export const cycleBegin = (
  date: moment.Moment,
  settings: Settings
): Readonly<moment.Moment> => {
  if (settings.sex === "male") {
    return moment(date).startOf("month");
  }

  checkDeps(settings);
  if (!CACHE.cycleBegin[date.unix()]) {
    CACHE.cycleBegin[date.unix()] = Object.freeze(
      moment(date).subtract(
        ((date.diff(moment(settings.periodStart).add(7, "days"), "days") % 28) +
          28) %
          28,
        "days"
      )
    );
  }
  return CACHE.cycleBegin[date.unix()];
};

export const cycleEnd = (
  date: moment.Moment,
  settings: Settings
): Readonly<moment.Moment> => {
  if (settings.sex === "male") {
    return moment(date).endOf("month").startOf("day");
  }

  checkDeps(settings);
  if (!CACHE.cycleEnd[date.unix()]) {
    CACHE.cycleEnd[date.unix()] = Object.freeze(
      moment(cycleBegin(date, settings)).add(27, "days")
    );
  }
  return CACHE.cycleEnd[date.unix()];
};

export default phase;
