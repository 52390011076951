import firebase from "firebase";
import moment from "moment";
import React from "react";

import { Calories } from "../lib/calories/types";
import { Data, Settings } from "../lib/firebase/types";

export interface AppContext {
  calories: Calories;
  data: Record<string, Data>;
  selected: moment.Moment;
  setSelected: (selected: moment.Moment) => void;
  settings: Settings;
  user: firebase.User | null;
}

const initialCalories: Calories = {
  bmr: 0,
  target: {
    follicular: 0,
    luteal: 0,
    menstrual: 0,
    ovulation: 0,
    none: 0,
  },
  tee: 0,
};

export const initialSettings: Settings = {
  activityLevel: "light",
  age: 0,
  height: 0,
  periodStart: moment().startOf("day").toDate(),
  sex: "female",
  target: 0,
  weight: 0,
};

const appContext = React.createContext<AppContext>({
  calories: initialCalories,
  data: {},
  selected: moment().startOf("day"),
  setSelected: () => {},
  settings: initialSettings,
  user: null,
});

export default appContext;
