import * as Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useContext, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";

import { COLORS } from "../App/constants";
import { CyclePhase } from "../App/types";

import appContext from "../../contexts/appContext";

import Firebase from "../../lib/firebase";
import phase, { phaseBegin, cycleBegin, cycleEnd } from "../../lib/phase";

const moment = extendMoment(Moment);

const FullRow = styled(Row)`
  height: 100%;
`;

const CenterCol = styled(Col)`
  margin-top: 3vh;
  position: relative;
  text-align: center;
`;

const Header = styled.div`
  font-size: 3vh;
  margin-bottom: 1vh;
`;

interface InfoProps {
  color?: "green" | "red" | "inherit";
}
const Info = styled.div`
  color: ${({ color = "inherit" }: InfoProps) => color};
  font-size: 2vh;
`;

const InfoBig = styled(Info)`
  font-size: 3vh;
`;

const Date = styled.div`
  font-size: 3vh;
  margin-bottom: 1vh;
`;

interface TargetProps {
  phase: CyclePhase;
}
const Target = styled.div`
  background: ${(props: TargetProps) => COLORS[props.phase]};
  border-radius: 10px;
  font-size: 2vh;
  margin: 0 auto;
  padding: 2vh;
  width: 200px;
`;

const BackLink = styled.div`
  font-size: 2vh;
`;

const TargetValue = styled.div`
  font-size: 2.5vh;
  font-weight: 600;
`;

interface InputProps {
  actual: number;
  target: number;
}
const Input = styled(Form.Control)`
  color: ${(props: InputProps) =>
    props.actual > props.target ? "red" : "green"};
  font-size: 2.5vh;
  text-align: center;
`;

const Navigate = styled.div`
  font-size: 16vh;
  line-height: 24vh;
  position: absolute;
  top: 0;
`;

const NavigateLeft = styled(Navigate)`
  left: 20px;
`;

const NavigateRight = styled(Navigate)`
  right: 20px;
`;

const Display = () => {
  const { calories, data, selected, setSelected, settings, user } = useContext(
    appContext
  );
  const settingsDoc = Firebase.instance().getSettingsDoc(user);

  const today = moment().startOf("day");
  const selectedUnix = selected.unix();
  const todayUnix = today.unix();

  // Selected data
  const selectedPhase = useMemo(
    () => phase(moment.unix(selectedUnix), settings),
    [selectedUnix, settings]
  );
  const selectedTarget = calories.target[selectedPhase];
  const selectedDoc = settingsDoc
    ?.collection("data")
    .doc(selectedUnix.toString());
  const selectedData = data[selectedUnix.toString()];

  // Current phase data
  const currentPhase = useMemo(() => phase(moment.unix(todayUnix), settings), [
    todayUnix,
    settings,
  ]);
  const currentPhaseBegin = useMemo(
    () => phaseBegin(moment.unix(todayUnix), settings),
    [todayUnix, settings]
  );
  const currentPhaseRange = useMemo(
    () =>
      moment.range(currentPhaseBegin, currentPhaseBegin.clone().add(6, "days")),
    [currentPhaseBegin]
  );
  const currentTarget = calories.target[currentPhase];
  const currentPhaseTarget = currentTarget * 7;
  const currentPhaseActual = useMemo(
    () =>
      Array.from(currentPhaseRange.by("day")).reduce((actual, day) => {
        const dayData = data[day.unix().toString()];
        return actual + (dayData ? dayData.calories : currentTarget);
      }, 0),
    [currentPhaseRange, currentTarget, data]
  );
  const currentPhaseOver = currentPhaseActual > currentPhaseTarget;

  // Current cycle data
  const currentCycleBegin = useMemo(
    () => cycleBegin(moment.unix(todayUnix), settings),
    [todayUnix, settings]
  );
  const currentCycleEnd = useMemo(
    () => cycleEnd(moment.unix(todayUnix), settings),
    [todayUnix, settings]
  );
  const currentCycleRange = useMemo(
    () => moment.range(currentCycleBegin, currentCycleEnd),
    [currentCycleBegin]
  );
  const currentCycleTarget = useMemo(
    () =>
      Array.from(currentCycleRange.by("day")).reduce((target, day) => {
        return target + calories.target[phase(day, settings)];
      }, 0),
    [calories.target, currentCycleRange, settings]
  );
  const currentCycleActual = useMemo(
    () =>
      Array.from(currentCycleRange.by("day")).reduce((actual, day) => {
        const dayData = data[day.unix().toString()];
        return (
          actual +
          (dayData ? dayData.calories : calories.target[phase(day, settings)])
        );
      }, 0),
    [calories.target, currentCycleRange, data, settings]
  );
  const currentCycleOver = currentPhaseActual > currentPhaseTarget;

  // Comparisons
  const isToday = selected.isSame(today);

  return (
    <FullRow>
      <CenterCol md={{ order: 1, span: 4 }} xs={{ order: 2, span: 12 }}>
        <Header>
          Phase Progress (
          {currentPhase[0].toUpperCase() + currentPhase.slice(1)})
        </Header>
        <Info>
          {currentPhaseRange.start.format("MM/DD/YYYY")}
          {" - "}
          {currentPhaseRange.end.format("MM/DD/YYYY")}
        </Info>
        <InfoBig>Target: {currentPhaseTarget.toLocaleString()}</InfoBig>
        <InfoBig color={currentPhaseOver ? "red" : "green"}>
          Actual: {currentPhaseActual.toLocaleString()}
        </InfoBig>
        {currentPhaseActual !== currentPhaseTarget && (
          <InfoBig color={currentPhaseOver ? "red" : "green"}>
            ({currentPhaseOver ? "+" : "-"}
            {Math.abs(currentPhaseActual - currentPhaseTarget).toLocaleString()}
            )
          </InfoBig>
        )}
      </CenterCol>
      <CenterCol md={{ order: 2, span: 4 }} xs={{ order: 1, span: 12 }}>
        <Target phase={selectedPhase}>
          <Date>
            {isToday ? "Today" : selected.format("MM/DD/YYYY")}
            {!isToday && (
              <BackLink>
                (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelected(today);
                  }}
                >
                  Back to Today
                </a>
                )
              </BackLink>
            )}
          </Date>
          Target:
          <TargetValue>{selectedTarget.toLocaleString()}</TargetValue>
          <Form>
            <Form.Group as={Col}>
              <Form.Label>Actual:</Form.Label>
              <Input
                actual={selectedData?.calories || 0}
                target={selectedTarget}
                type="tel"
                pattern="[0-9]+(\.[0-9])?"
                value={selectedData?.calories || 0}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  e.target.validity.valid &&
                  selectedDoc &&
                  selectedDoc.set(
                    {
                      calories: parseInt(e.target.value),
                      date: selected.toDate(),
                    },
                    { merge: true }
                  )
                }
              />
            </Form.Group>
          </Form>
        </Target>
        <NavigateLeft
          className="d-md-none"
          onClick={() => setSelected(moment(selected).subtract(1, "day"))}
        >
          &lsaquo;
        </NavigateLeft>
        <NavigateRight
          className="d-md-none"
          onClick={() => setSelected(moment(selected).add(1, "day"))}
        >
          &rsaquo;
        </NavigateRight>
      </CenterCol>
      <CenterCol md={{ order: 3, span: 4 }} xs={{ order: 3, span: 12 }}>
        <Header>Cycle Progress</Header>
        <Info>
          {currentCycleRange.start.format("MM/DD/YYYY")}
          {" - "}
          {currentCycleRange.end.format("MM/DD/YYYY")}
        </Info>
        <InfoBig>Target: {currentCycleTarget.toLocaleString()}</InfoBig>
        <InfoBig color={currentCycleOver ? "red" : "green"}>
          Actual: {currentCycleActual.toLocaleString()}
        </InfoBig>
        {currentCycleActual !== currentCycleTarget && (
          <InfoBig color={currentCycleOver ? "red" : "green"}>
            ({currentCycleOver ? "+" : "-"}
            {Math.abs(currentCycleActual - currentCycleTarget).toLocaleString()}
            )
          </InfoBig>
        )}
      </CenterCol>
    </FullRow>
  );
};

export default Display;
