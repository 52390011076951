import moment from "moment";
import React, { useContext } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import styled from "styled-components";

import appContext from "../../contexts/appContext";

import calories from "../../lib/calories";
import { ActivityLevel, Sex } from "../../lib/calories/types";
import Firebase from "../../lib/firebase";
import { Settings, SettingsKey } from "../../lib/firebase/types";

import { Props } from "./types";

const DatePickerWrapper = ({ className, ...rest }: ReactDatePickerProps) => (
  <DatePicker wrapperClassName={className} {...rest} />
);

const StyledDatePicker = styled(DatePickerWrapper)`
  display: block;
`;

const Spacer = styled.div`
  flex: 1;
`;

const Footer = styled(Modal.Footer)`
  display: flex;
`;

const Header = styled(Modal.Header)`
  font-size: 18px;
`;

const BoldControl = styled(Form.Control)`
  font-weight: bold;
`;

const BoldHighlightedControl = styled(BoldControl)`
  background: #ccff15;
  text-align: center;
`;

const CenterLabel = styled(Form.Label)`
  text-align: center;
`;

const SettingsModal = (props: Props) => {
  const { settings, user } = useContext(appContext);
  const doc = Firebase.instance().getSettingsDoc(user);

  if (!doc || !user || !user.email) return null;
  const email = user.email;

  const generateOption = <T extends ActivityLevel | Sex | number>(
    value: T,
    label?: string
  ) => (
    <option key={value} value={value}>
      {label || value}
    </option>
  );

  const setValue = <Key extends SettingsKey>(key: Key) => (
    value: Settings[Key]
  ) => doc.set({ [key]: value }, { merge: true });

  const signOut = () => {
    const ok = window.confirm("Really sign out?");
    if (ok) {
      Firebase.instance().auth.signOut();
    }
  };

  const bmr = calories.bmr(
    settings.sex,
    settings.weight,
    settings.height,
    settings.age
  );
  const tee = calories.tee(bmr, settings.activityLevel);
  const target = calories.target(tee, settings.target);

  return (
    <Modal centered show={props.isOpen} onHide={props.close}>
      <Header closeButton>Settings</Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <BoldControl plaintext readOnly value={email} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Sex</Form.Label>
            <Form.Control
              as="select"
              value={settings.sex}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue("sex")(e.target.value as Sex)
              }
            >
              {generateOption<Sex>("female", "Female")}
              {generateOption<Sex>("male", "Male")}
            </Form.Control>
          </Form.Group>

          {settings.sex === "female" && (
            <Form.Group>
              <Form.Label>Last Period Start Date</Form.Label>
              <StyledDatePicker
                customInput={<Form.Control type="text" />}
                selected={settings.periodStart}
                onChange={(date) => {
                  setValue("periodStart")(
                    moment(date || undefined)
                      .startOf("day")
                      .toDate()
                  );
                }}
              />
            </Form.Group>
          )}

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Age</Form.Label>
              <Form.Control
                as="select"
                value={settings.age}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValue("age")(parseInt(e.target.value))
                }
              >
                {[...Array(63)].map((_, i) => {
                  const value = i + 18; // Start at 18, end at 80
                  return generateOption<number>(value);
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Height</Form.Label>
              <Form.Control
                as="select"
                value={settings.height}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValue("height")(parseInt(e.target.value))
                }
              >
                {[...Array(19)].map((_, i) => {
                  const value = i + 60; // Start at 5', end at 6' 6"
                  return generateOption<number>(
                    value,
                    `${Math.floor(value / 12)}' ${value % 12}"`
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Weight</Form.Label>
              <Form.Control
                type="tel"
                pattern="[0-9]+(\.[0-9])?"
                value={settings.weight}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  e.target.validity.valid &&
                  setValue("weight")(parseFloat(e.target.value) || 0)
                }
              />
            </Form.Group>
          </Form.Row>

          <Form.Group>
            <Form.Label>Activity Level</Form.Label>
            <Form.Control
              as="select"
              value={settings.activityLevel}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue("activityLevel")(e.target.value as ActivityLevel)
              }
            >
              {generateOption<ActivityLevel>("none", "Sedendary")}
              {generateOption<ActivityLevel>("light", "Light activity")}
              {generateOption<ActivityLevel>(
                "medium",
                "Active or moderately active"
              )}
              {generateOption<ActivityLevel>("heavy", "Vigorously active")}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Target Weight Change</Form.Label>
            <Form.Control
              as="select"
              value={settings.target}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue("target")(parseFloat(e.target.value))
              }
            >
              {generateOption<number>(-1.5, "Lose 1.5 lb/week")}
              {generateOption<number>(-1, "Lose 1 lb/week")}
              {generateOption<number>(-0.5, "Lose 0.5 lb/week")}
              {generateOption<number>(0, "Maintain")}
              {generateOption<number>(0.5, "Gain 0.5 lb/week")}
              {generateOption<number>(1, "Gain 1 lb/week")}
              {generateOption<number>(1.5, "Gain 1.5 lb/week")}
            </Form.Control>
          </Form.Group>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>BMR</Form.Label>
              <BoldControl plaintext readOnly value={bmr} />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>TEE</Form.Label>
              <BoldControl plaintext readOnly value={tee} />
            </Form.Group>

            <Form.Group as={Col}>
              <CenterLabel>Target calories</CenterLabel>
              <BoldHighlightedControl plaintext readOnly value={target} />
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
      <Footer>
        <Button variant="danger" onClick={signOut}>
          Sign Out
        </Button>
        <Spacer />
        <Button onClick={props.close}>Done</Button>
      </Footer>
    </Modal>
  );
};

export default SettingsModal;
