import { ActivityLevel, Sex } from "./types";

const lb_per_kg = 2.2046;
const inch_per_cm = 0.3937;

const factors: Record<Sex, number> = {
  female: -161,
  male: 5,
};

const activityFactor: Record<ActivityLevel, number> = {
  none: 1.2,
  light: 1.37,
  medium: 1.55,
  heavy: 1.72,
};

export const bmr = (
  sex: Sex,
  weight: number,
  height: number,
  age: number
): number =>
  Math.floor(
    factors[sex] +
      10 * (weight / lb_per_kg) +
      6.25 * (height / inch_per_cm) -
      5 * age
  );

export const tee = (bmr: number, activityLevel: ActivityLevel): number =>
  Math.floor(bmr * activityFactor[activityLevel]);

export const target = (tee: number, target: number) => tee + 500 * target;
