import { CyclePhase } from "./types";

export const COLORS: Record<CyclePhase, string> = {
  menstrual: "#ea9999",
  follicular: "#b6d7a8",
  ovulation: "#a4c2f4",
  luteal: "#ffe599",
  none: "#b6d7a8",
};

export const FACTORS: Record<CyclePhase, number> = {
  menstrual: 1.25,
  follicular: 0.8,
  ovulation: 0.9,
  luteal: 1.05,
  none: 1,
};
